/* Modal overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Gjennomsiktig svart bakgrunn */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Sørg for at modal vises over alt annet */
}

/* Modal innhold */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px; /* Begrens bredde for større skjermer */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Diskret skygge */
  text-align: center;
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in effekt */
}

/* Modal tittel */
.modal-content h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #333;
}

/* Input-felter i modal */
.modal-content .dropdown {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Knappene i modal */
.modal-content button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Bekreft-knapp */
.modal-content button:first-of-type {
  background-color: #4bcd4b;
  color: #fff;
}

.modal-content button:first-of-type:hover {
  background-color: #2fa12f;
}

/* Avbryt-knapp */
.modal-content button:last-of-type {
  background-color: #dc3545;
  color: #fff;
}

.modal-content button:last-of-type:hover {
  background-color: #c82333;
}

/* Fade-in animasjon for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Parkeringsplass-stil */
.space-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Header for parkeringsplass med knapp */
.space-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservations-list p{
  margin: 0;
}

/* Reserver-knappen */
.reserve-button {
  background-color: #4bcd4b;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.reserve-button:hover {
  background-color: #2fa12f;
}

.reservations-list ul{
  padding: 0px;
}

/* Reservasjonsdetaljer */
.reservation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0;
  margin: 5px 0;
  font-size: 0.8em;
}

.reservation-email {
  font-size: 0.85em;
  color: #666;
  margin-left: 10px;
}

/* Søppelbøtteikon */
.delete-icon {
  color: red;
  margin-left: 10px;
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.delete-icon:hover {
  color: darkred;
}
