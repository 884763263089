/* Style for the login container */
.login-container {
  display: flex;
}

.login-content{
  flex-direction: row;
  align-content: center;
  text-align: center;
  width: 100%;
}

/* Style for input fields */
.input {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  max-width: 300px;
}

/* General button style */
button {
  padding: 10px 20px;
  max-width: 300px;
  font-size: 16px;
  cursor: pointer;
}

/* Style for the "or" text */
.or-text {
  margin: 15px 0;
  font-size: 14px;
  color: #666;
}

/* Google login button style */
.login-content button.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  margin: 0 auto;
  font-size: 16px;
  color: #333; /* Dark gray text */
  background-color: #ffffff; /* White background */
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  text-align: left;
}

.login-content button.google-login-button:hover {
  background-color: transparent;
  color: #333;
}

/* Google logo style inside the button */
.google-logo {
  width: 20px;
  height: 20px;
}
