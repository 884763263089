/* index.css */
body, html {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

h1, h2, h3 {
  font-weight: 600;
  color: #333;
}

a {
  color: #ff5a5f;
  text-decoration: none;
}

a:hover {
  color: #ff767b;
}

button {
  padding: 10px 15px;
  border: none;
  background-color: #ff5a5f;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff767b;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.navbar {
  display: flex;
  gap: 15px; /* Mellomrom mellom menyobjektene */
  align-items: center;
}